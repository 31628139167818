@import '~@kmx/legos-theme/src/index';

.dropdown {
    position: relative;
    display: block;
}

.dropdown-content {
    position: absolute;
    background-color: white;
    @include kmx-elevation(12);
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    padding: 8px 0 16px;

    .option {
        color: kmx-color('slate', 900);
        overflow: hidden;
        cursor: pointer;
        margin: 0 !important;
        padding: 8px 16px !important;
        list-style-type: none;

        &:hover {
            background-color: kmx-color('blue', 700);
            color: white;
        }
    }

    .message {
        color: kmx-color('slate', 900);
        overflow: hidden;
        white-space: nowrap;
        margin: 0 !important;
        padding: 8px 16px !important;
        list-style-type: none;
    }
}
