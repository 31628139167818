@import '~@kmx/legos-theme/src/colors/index';
@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0 30px;

    .smsForm {
        border-radius: 8px;
        border: 1px solid lightgray;
        padding: 16px;
        margin-bottom: 30px;
        text-align: left;
    }

    .continueHereWrapper {
        text-align: center;
        .button {
            margin-top: 10px;
        }
    }

    .button {
        @extend .kmx-button;
        @extend .kmx-button--primary;

        border-radius: 8px;
        height: 3rem;
        width: 280px;
    }
}
