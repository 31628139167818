@import "~@kmx/legos-theme/src/layout/_variables.scss";

.activeOffersSubtitleContainer {
    margin-top: -2.2em;
}

.activeOffersTitle {
    letter-spacing: 0.5px;
    line-height: 39px;
    text-align: center;
}

.activeOffersSubtitle {
    color: #053361;
    text-align: center;
    margin: 0.5rem 0;
}
