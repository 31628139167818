@import "~@kmx/legos-theme/src/layout/_variables.scss";

.checkmarkIcon {
    height: 66.67px;
    width: 66.67px;
}

.carimage {
    display: block;
    width: 100%;
}

.title {
    margin-top: -2em;
     > h4 {
        margin-bottom: .5em;
     }
}

.subtitleContainer {
    margin-top: -2em;
    margin-bottom: 2em;
    margin-left: 1em;
    text-align: left;
}
