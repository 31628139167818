@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.container {
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
}

.title {
    margin-top: -2em;
    > h4 {
        margin-bottom: .5em;
    }
}

.instructions {
    h2 {
        margin: 30px 0 5px;
    }
    ol {
        counter-reset: step-counter;
        li {
            list-style-type: none !important;
            margin: 0 10px !important;
            counter-increment: step-counter;
            color: kmx-color('slate', 900);
            i {
                color: kmx-color('blue', 900);
                font-weight: bold;
                font-style: normal;
            }

            &::before {
                content: counter(step-counter);
                color: white;
                font-weight: bold;
                background-color: kmx-color('blue', 900);
                border-radius: 50%;
                display: block;
                text-align: center;
                margin: 20px auto 10px;
                font-size: 20px;
            }
            &:nth-child(1)::before {
                width: 32px;
            }
            &:nth-child(2)::before {
                width: 38px;
            }
            &:nth-child(3)::before {
                width: 44px;
            }
        }
    }
}

.expressPickupImg {
    width: 324px;
}

.requirements {
    .requirementsCard {
        border: 1px solid kmx-color('slate', 300);
        border-left: 8px solid kmx-color('yellow', 600);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 0 20px;
        svg {
            width: 40px;
            fill: kmx-color('blue', 900);
        }
        .requirementsHeader {
            display: flex;
            justify-content: space-between;
        }
        .requirementsTitle {
            font-size: 16px;
        }
        ul {
            margin: 0 !important;
        }
        li {
            text-align: left;
            list-style-type: none !important;
            margin: 0 !important;
            display: flex;
            align-items: flex-start;
            svg {
                width: 24px;
            }
            * > div {
                width: 240px;
            }
        }
        .requirementsItemHtml, .requirementsItemText, .requirementsItemDetails {
            font-size: 14px;
            margin: 0 0 15px 10px;
        }
        .requirementsTitle, .requirementsItemText, .requirementsItemHtml i {
            color: kmx-color('blue', 900);
            font-weight: bold;
            font-style: normal;
        }
    }
}

.questions {
    margin: 33px 0;
    .questionsTitle p {
        color: kmx-color('blue', 900);
        font-weight: bold;
        margin-bottom: 10px;
    }
    p {
        margin: 0;
    }
}
