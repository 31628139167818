@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '/src/utils/mediaQueries/mediaQueries';

.main {
    width: fit-content;
    @include nonMobile {
        & > * {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }
    }
    hzn-divider {
        width: 100%;
        margin: 40px 0;
    }
    a {
        color: kmx-color('blue', 600);
        text-decoration: underline;
        cursor: pointer;
    }
}
