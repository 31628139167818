@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;

    .image {
        width: 50%;
        object-fit: contain;
        max-width: 100%;
    }

    .header {
        margin: 1rem 0;
    }

    .subtitle {
        margin-bottom: 0.25rem;
        margin-top: 0;
    }

    .card {
        text-align: left;
        width: 82%;
    }

    .button {
        border-radius: 8px;
        margin: 20px 10px;
        height: 3rem;
        width: 100%;
    }

    &.kioskUX {
        .buttonContainer {
            display: flex;
            .button {
                min-width: 170px;
            }
        }
        .card {
            border: none;
        }
    }
}

