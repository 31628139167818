.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
}

.iconClose {
    width: 20px;
    margin: -15px -15px 0 15px;
    cursor: pointer;
}

.dialog {
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
}
