@import '/src/utils/mediaQueries/mediaQueries';

.pciPageLayout {
    padding: 60px 25px;
    display: flex;
    justify-content: center;
    margin: auto;

    @include nonDesktop {
        background-image: linear-gradient(
                180deg,
                white 0 200px,
                #ffd900 200px 270px,
                #003366 270px 100%
        );
    }

    & > * {
        max-width: 768px;
    }
    @include tablet {
        & > * {
            max-width: 62%; // scaled to actual size of 93%
        }
    }
}
