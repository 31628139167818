@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;

    .image {
        width: 50%;
        object-fit: contain;
        max-width: 100%;
    }

    .header {
        margin: 0.5rem 0;
    }

    .subtitle {
        margin: 0;
    }

    .button {
        border-radius: 8px;
        margin-top: 1.5rem;
        height: 3rem;
        width: 100%;
    }

    .linkButton {
        color: kmx-color('blue', 600);
        margin-top: 0.5rem;
        padding: 0;
    }
}
