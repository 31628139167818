.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;

    .checkButtonRadios {
        display: flex;
        > * {
            margin: 30px 10px;
        }
    }

    .nextButton {
        border-radius: 8px;
        height: 3rem;
        width: 280px;
    }
}
