.address {
    display: flex;
    flex-direction: row;

    .address-field {
        flex: auto;
    }

    .apartment-field {
        flex: 2 1 auto;
        margin-right: 16px;
    }

    .city-field {
        flex: 2 1 auto;
    }

    .state-field {
        flex: 1 1 auto;
    }

    .zip-field {
        flex: 1 1 auto;
        margin-left: 16px;
    }

    .county-field {
        flex: 2 1 auto;
        margin-left: 16px;
    }
}

.overwrite-address-field {
    padding-top: 16px;
}

.address-alert {
    padding-top: 8px;
}
