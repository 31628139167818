.container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.logoImage {
    width: 235px;
    height: 180px;
}
