@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~sass-mq';

.innerGrid {
    grid-gap: 0;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;

    @include mq($from: medium) {
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.clickable {
    cursor: pointer;
}

.accordionQuestion {
    color: kmx-color('blue', 900);
    margin: 0px;
}

.accordionIcon {
    transition: transform 300ms ease-in-out;
}

.expansionIcon {
    text-align: center;

    span {
        line-height: 1em;
        text-align: center;
        position: absolute;
        height: 40px;
        width: 40px;
        margin-top: -32px;
        margin-left: 8em;
        border: none;
        background-color: white;

        @include mq($from: medium) {
            margin-left: 10em;
        }

        svg {
            fill: kmx-color('blue', 900);
            position: relative;
        }

        svg.accordionIconExpanded {
            transform: rotate(180deg);
            top: 8px;
        }

        svg.accordionIconCollapsed {
            transform: rotate(0deg);
            top: 9px;
        }
    }
}

.expansionIcon span:hover, .expansionIcon span:active:hover {
    border-radius: 100%;
    border-style: none;
}

.expansionIcon span:hover {
    background-color: rgba(23,115,207, .08);
}
.expansionIcon span:active:hover {
    background-color: rgba(23,115,207, .24);
}

.accordionContentExpand {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    margin-top: 24px;

    @include mq($from: medium) {
        margin-top: 32px;
    }

    p {
        margin: 0px;
    }
}

.accordionContentCollapsed {
    display: none;
}

hr {
    background-color: kmx-color('slate', 200);
    height: 1px;
    border: none;
    margin: 0px;
}
