.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
}

.scrollable {
    height: 400px;
    overflow-y: auto;
    width: 100%;
}

button.primary {
    border-radius: 8px;
    margin-top: 1.5rem;
    height: 48px;
    width: 343px;
}
