@import '~@kmx/legos-theme/src/colors/index';

.button {
    width: 150px;
    height: 65px;
    position: relative;
    text-transform: capitalize;
    font-size: 20px;
}
.buttonChecked {
    border: 1px solid kmx-color('blue', 600);

    .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        border-style: solid;
        border-width: 0 20px 20px 0;
        border-color: transparent kmx-color('blue', 600) transparent transparent;

        svg {
            position: absolute;
            top: 1px;
            left: 10px;
            height: 8px;
            fill: white;
        }
    }
}
.buttonWithIcon {
    display: flex;
    justify-content: start;
}

.icon {
    margin: 9px 14px 0 3px;
    svg {
        fill: kmx-color('blue', 900);
        width: 32px;
    }
}
