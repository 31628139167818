@import '~@kmx/legos-card/src/index';
@import "~@kmx/legos-theme/src/colors/index";

.container {
    width: 100%;
    &.nonKiosk {
        border: 1px solid kmx-color('slate', 200);
        border-radius: .9rem;
        padding: 16px 16px 16px 16px;
    }
}

.label {
    font-family: Lato, sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.checkboxes {
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 16px;

    label {
        margin: .5em;
        margin-right: 1em;
    }
}

.textInput {
    padding-top: 0;
    margin-top: -10px;
    border: none;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.kioskButtonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    .kioskButton {
        width: 290px;
        text-transform: none;
        font-size: 16px;
        margin: 10px;
    }
}
