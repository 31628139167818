@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-button/src/index';

.appointmentCard {
    margin: 25px 0 35px;
    svg {
        width: 55px;
        margin-bottom: 8px;
    }
    p {
        margin: 5px 0;
        a {
            font-size: 14px;
        }
    }
    .addressLineWithHeader {
        color: kmx-color('blue', 900);
    }
}
