@import "~@kmx/legos-theme/src/layout/_variables.scss";

.carimage {
    width: 75%;
}

.carimagecontainer {
    padding-bottom: .5em;
    text-align: center;
}
