@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.contentWrapper {
    position: relative;
}

.carImageBackground {
    background-image: linear-gradient(#FFFFFF, #E9E9E9);
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 230px;
    @media (min-width: 768px) {
        height: 250px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    max-width: 370px;
    margin: 0 auto;
}

.findMyOfferButtonContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .findMyOfferButton {
        @extend .kmx-button;
        @extend .kmx-button--primary;

        border-radius: 8px;
        margin-top: 1.5rem;
        height: 3rem;
        width: 100%;
    }
}

.inputControlWidth {
    width: 100%;
}
