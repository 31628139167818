.container {
    max-width: 370px;
    margin-top: 2em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .button {
        border-radius: 8px;
        margin-top: 1.5rem;
        height: 3rem;
        width: 100%;
    }
}

.storeHoursContainer {
    flex: 1;
    flex-wrap: wrap
}
