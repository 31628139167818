@import '~@kmx/legos-theme/src/web-fonts';
@import '~@kmx/legos-theme/src/index';
@import '~@kmx/legos-mini-header/src/index';
@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';
@import '~@kmx/legos-select/src/index';
@import '~@kmx/legos-text-field/src/index';
@import '~@kmx/legos-text-area/src/index';
@import '~@kmx/legos-checkbox/src/index';
@import '~@kmx/legos-radio-button/src/index';
@import '~@kmx/legos-form-field/src/index';
@import '~@kmx/legos-card/src/index';
@import "~@kmx/legos-theme/src/colors/index";

/* Default height/width will target smartphones, secondary target will be tablets, last target will be full size PC desktops */
body {
    background: #FFFFFF;
    margin: 15px auto;
}

// extremely common, not worth creating many separate classes in each css module
.kmx-blue-900 {
    color: kmx-color('blue', 900);
}

html, body, #root {
    height: 100%;
    margin: 0;
}
