@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: flex;
    background-color: kmx-color('yellow', 200);
    padding: 15px;
    margin: 20px -20px 30px;
    svg {
        width: 30px;
        margin-right: 10px;
    }
}
