@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    max-width: 370px;
    margin: 0 auto;

    .heading {
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
}

.nextButtonContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .nextButton {
        @extend .kmx-button;
        @extend .kmx-button--primary;

        border-radius: 8px;
        margin-top: 1.5rem;
        height: 3rem;
        width: 100%;
    }
}
