@import '/src/utils/mediaQueries/mediaQueries.scss';

.item {
    border-bottom: 1px dashed #565d65;
    width: 100%;
    .itemGrid {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 85px 1fr;
        grid-gap: 16px;
        align-items: center;
        min-width: 100%;

        //TODO Use Horizon tones/variants when better documentation is found
        .label {
            width: 100%;
            max-width: 85px;
            text-wrap: normal;
            //styleName: Caption/line-height=base;
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
        }

        //TODO Use Horizon tones/variants when better documentation is found
        .value {
            font-family: Lato;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: #565d65;
        }
    }

    .children {
        margin: 24px 0;
    }
}
