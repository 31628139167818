.card {
    max-width: 300px;

    img {
        margin-bottom: 20px;
        width: 100%;
    }
    hzn-stack {
        padding: 0 20px 30px;
    }
    hzn-heading {
        margin-top: 5px;
        .smallBar {
            border-left: 1px solid darkgrey;
            height: 17px;
            margin: 0 10px;
            display: inline-block;
        }
    }
}
