@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
}

.title {
    width: 288px;
    height: 50px;
    color: blue/blue-900 #053361
}

.subtitle {
    width: 288px;
    height: 50px;
    margin-top: .5rem;
    color: slate/slate-900 #2A343D;
    font-family: 'Lato', sans-serif;
}
