.carCard {
    width: 260px;
    margin: auto;
    padding: 15px;
    overflow: hidden;
    text-align: left;
    border-width: 0;

    img {
        margin: -16px;
        margin-bottom: 10px;
        width: 291px;
    }
    h3 {
        margin: 0 2px;
    }
    .model {
        font-family: Frutiger, ‘Frutiger Linotype’, Univers, Calibri, ‘Gill Sans’, ‘Gill Sans MT’, ‘Myriad Pro’, Myriad, ‘DejaVu Sans Condensed’, ‘Liberation Sans’, ‘Nimbus Sans L’, Tahoma, Geneva, ‘Helvetica Neue’, Helvetica, Arial, sans-serif;
        font-weight: 500;
    }
    .visitReason {
        text-transform: uppercase;
        font-size: 11px;
        color: #023363;
        background-color: #FFD700;
        border-radius: 11px;
        padding: 4px 10px;
        display: inline-block;
        margin-top: 7px;
    }
}
