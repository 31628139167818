@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.container {
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
}

.title {
    margin-top: -2em;
     > h4 {
        margin-bottom: .5em;
     }
}

.instructionsEpu {
    li {
        color: kmx-color('slate', 900);
        text-align: left;
    }
    .instructionsEpuXf {
        color: kmx-color('slate', 900);
        margin-bottom: 30px;
    }
}

.checkmarkIcon {
    height: 66.67px;
    width: 66.67px;
}
