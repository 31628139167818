@import "~@kmx/legos-theme/src/layout/_variables.scss";

.carImageTitle {
    font-weight: bold;
    font-family: 'Car Max Sharp Sans Display', sans-serif;
    margin-top: -.70em;
    margin-left: auto;
    margin-right: auto;
}

.carImageSubtitle {
    font-weight: normal;
    font-family: 'Lato', sans-serif;
    margin-left: auto;
    margin-right: auto;
    border: none;
    margin-top: -2em;
}
