@import '~@kmx/legos-theme/src/colors/index';
@import '/src/utils/mediaQueries/mediaQueries';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: kmx-color('yellow', 600);

    .button {
        border-radius: 8px;
        margin: 120px 0 100px;
        height: 3rem;
        width: 343px;
        background-color: kmx-color('blue', 900);
        color: white;
    }

    .imageContainer {
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: kmx-color('yellow', 600);
            position: absolute;
            opacity: 0.6;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }
}
