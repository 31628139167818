.modalOverlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
    z-index: 100;
}
.modalWindow {
    position: absolute;
    inset: 50% auto auto 50%;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 7px;
    outline: none;
    padding: 30px 30px 50px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-height: 92vh;
    z-index: 101;
}

// horizon is not react-specific, so we're wrapping buttons into divs with "onClick" prop set;
// this css makes sure the button displays as intended
.buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
