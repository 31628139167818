@import '/src/utils/mediaQueries/mediaQueries';

@include mobile {
    .container {
        padding-top: 28px;
        border-top: 1px dashed #AAAFB3;
    }
}
.dialog {
    max-width: 420px;
}
.documentsHeader {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
}
.iconClose {
    width: 20px;
    cursor: pointer;
}
