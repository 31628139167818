.imageWrapper {
    display: flex;
    align-items: center;

    .epuSign {
        width: 120px;
        border-radius: 14px;
        z-index: 2;
    }
    .epuParkingLot {
        width: 370px;
        z-index: 1;
        margin-left: -100px;
    }
    .epuSignProp {
        width: 28px;
    }

    &.imageDesktop {
        flex-direction: row;
    }
    &.imageMobile {
        flex-direction: column;
        .epuSign,
        .epuSignProp {
            box-shadow: -31px 22px 54px -20px rgb(97, 97, 97, 0.6);
        }

    }
}
