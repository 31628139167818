@import '/src/utils/mediaQueries/mediaQueries';

$tablet-scale: 1.5;

.pageLayout {
    overflow: hidden;
    @include tablet {
        .contentWrapper {
            transform: scale($tablet-scale);
            -webkit-transform-origin: top;
            transform-origin: top;
        }
    }
    .footerWrapper {
        position: absolute;
        width: 100%;
    }
}
