@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '/src/utils/mediaQueries/mediaQueries.scss';

.main {
    max-width: 454px;
    a {
        color: kmx-color('blue', 600);
        text-decoration: underline;
        cursor: pointer;
    }
}
.ctaContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}
.heading {
    margin-bottom: 20px;
}
