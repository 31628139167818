@import '~@kmx/legos-theme/src/colors/index';
@import '/src/utils/mediaQueries/mediaQueries';

$footer-height: 125px;

.footer {
    display: flex;
    justify-content: center;

    width: 100%;
    height: $footer-height;
    background-color: kmx-color('blue', 600);
    font-family: Lato, Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', Verdana, 'Verdana Ref', sans-serif;

    .footer-content{
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 32px;
        width: 100%;
        text-align: center;

        .footer-content__logo{
            width: 180px;
            margin-right: 32px;
        }

        .footer-content__legal__nav {

            .footer-content__legal__nav__item {
                color: #fff!important;
                display: inline-block;
                text-decoration: none;
                font-size: 10px;
                @include tablet {
                    font-size: 18px;
                }
                margin-right: 16px;
                text-transform: uppercase;
            }
        }

        .footer-content__legal__copyright{
            font-size: 12px;
            opacity: .8;
            @include tablet {
                font-size: 20px;
                line-height: 40px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    footer {
        height: 150px;

        .footer-content{
            color: white;
            display: block;
            justify-content: center;
            flex-direction: column;
            margin: 24px;
            width: 100%;

            .footer-content__legal__nav{
                font-size: 10px;
                margin-bottom: 16px;
            }

            .footer-content__legal__copyright{
                margin-bottom: 16px;
                margin-right: 0px;
                vertical-align: middle;
            }

        }
    }
}
