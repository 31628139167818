.container {
    .detailsRow {
        display: flex;
        .label {
            min-width: 60px;
        }
    }

    .storeRow {
        display: flex;
        align-items: start;
        .iconWrapper {
            margin-right: 8px;
            svg {
                width: 16px;
                margin-top: 3px;
                fill: #1773CF;
            }
        }
        &:first-of-type {
            margin-bottom: 10px;
        }
    }

    .dashed {
        border: none;
        width: 100%;
        background: repeating-linear-gradient(90deg,#777,#777 6px,transparent 6px,transparent 12px);
    }
}
