// Tablet strategy: they show scaled-up content, but only when in portrait mode
// (note: desktops will display unscaled content, it looks better)
@mixin tablet {
    @media (min-width: 768px) and (max-aspect-ratio: 1/1) {
        @content;
    }
}

@mixin nonDesktop {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin nonMobile {
    @media (min-width: 768px) {
        @content;
    }
}
