@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: grid;
    padding: 2rem;
    max-width: 370px;
    align-items: center;
    margin: 0 auto;
}

.offer-card {
    background-color: kmx-color("blue", 600);
    border-radius: 8px;
    padding: 1em;
    color: $white;

    .line-1 {
        display: block;
        color: $white;
        text-align: center;
    }

    .line-2 {
        display: block;
        color: $white;
        text-align: center;
    }

    .line-3 {
        display: block;
        color: $white;
        text-align: center;
    }

    .line-4 {
        display: block;
        color: $white;
        text-align: center;
    }
}

.requirements {
    margin: 1em 0;
    color: kmx-color("slate", 900);
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;

    ul.list {
        margin: 0 2em;
        color: kmx-color("slate", 900);
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;

        p {
            margin: 0 1em;
            color: kmx-color("slate", 900);
            font-size: 14px;
        }

       & > li {
            margin: 0;

        }
    }
}

.acknowledge {
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 16px;

    label {
        margin: .5em;
        margin-right: 1em;
    }
}

.legal {
    color: kmx-color("slate", 600);

    &:before {
        display: inline;
        content: "* ";
        vertical-align: top;
    }
}

button.primary {
    border-radius: 8px;
    margin-top: 1.5rem;
    height: 3rem;
    width: 100%;
}
