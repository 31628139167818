.hide {
    display: none;
}

.show {
    display: block;
}

.pageWrapper{
    height: 100%;
}
