.vehicle-info {
    padding-bottom: 8px;

    .vehicle-title {
        color: kmx-color("slate", 600);
        font-weight: bold;
        margin: 1em 0;
    }

    ul.vehicle-list {
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0;
        color: kmx-color("slate", 600);
        font-size: 16px;
        line-height: 25px;

        & > li {
            list-style-type: none;
            list-style: none;
            line-height: 1em;
        }
    }
}

.questionnaireContainer {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #dedfe0;
    border-radius: 8px;
    margin: 0 -16px;
    padding: 16px;
}

