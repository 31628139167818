@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.title {
    font-weight: bold;
    font-family: 'Car Max Sharp Sans Display Bold', sans-serif;
    margin-top: -.70em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.subtitle {
    font-weight: normal;
    font-family: 'Lato', sans-serif;
    margin-left: auto;
    margin-right: auto;
    border: none;
}

.radioButtonsCard {
    border-radius: 8px;
    border: 1px solid lightgray;
    padding: 10px;
}

.radioButtons {
    margin-top: 12px;
    margin-left: 10px;
}

.licensePlate {
    margin-top: 8px;
}

.continueButtonContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .continueButton {
        @extend .kmx-button;
        @extend .kmx-button--primary;

        border-radius: 8px;
        margin-top: 1.5rem;
        height: 3rem;
        width: 100%;
    }
}
