.confirmedLabel {
    background-color: #1B6B00;
    margin: auto;
    padding: 8px 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .confirmedIcon {
        fill: white;
        width: 20px;
        margin: 0 5px 0 0;
    }
}
