@import '~@kmx/legos-theme/src/colors/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
}

.title {
    width: 288px;
    color: blue/blue-900 #053361;
    margin-bottom: unset;
}

.subtitle {
    width: 288px;
    height: 50px;
    margin-top: 0.5rem;
    color: slate/slate-900 #2a343d;
    font-family: 'Lato', sans-serif;
    margin-top: 1.9em;
}
