@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-disclosure/src/index';

.accordion-container {
    text-align: left;

    .faq-list {
        .faq-list-item {
            margin-left: 20px;
        }
    }
}
