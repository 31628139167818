@import '~@kmx/legos-button/src/index';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    min-width: 300px;
    margin: 0 auto;
    text-align: center;

    .steps {
        width: 100%;
    }

    .header {
        margin: 0.5rem 0;
    }
}

button.primary {
    @extend .kmx-button;
    @extend .kmx-button--primary;

    border-radius: 8px;
    margin-top: 1.5rem;
    height: 3rem;
    width: 100%;
}
