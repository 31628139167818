@import '~@kmx/legos-theme/src/typography/index';
@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-button/src/index';


.container {
    background-color: kmx-color("blue", 600);
    border-radius: 8px;
    padding: 1em;
    color: $white;
    text-align: center;
    width: 298px;
    height: 107px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.9em;
}

.offerCode {
    color: white;
    font-weight: bold;
}


