@import "~@kmx/legos-theme/src/layout/_variables.scss";
@import '~@kmx/legos-button/src/index';

.container {
    align-items: center;
    padding: 2rem;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
}

.imageContainer {
    svg {
        width: 100%;
    }
}

.title {
    margin-top: -2em;
}
.main {
    margin: 25px auto 40px;
    border: 1px solid kmx-color('slate', 300);
    border-radius: 6px;
    padding: 25px 40px;
    .instructions {
        color: kmx-color('blue', 900);
        margin-bottom: 25px;
    }
    .cta {
        width: 100%;
        border-radius: 4px;
    }
}
