.title {
    margin-top: -1em;
}

.subtitle {
    margin-top: -.5em;
    text-align: center;
}

.icon {
    width: auto;
    height: 60px;
}
