@import '~@kmx/legos-card/src/index';
@import "~@kmx/legos-theme/src/index";
@import "~@kmx/legos-theme/src/colors/index";

.selectionCard {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    border: 1px solid kmx-color('slate', 200);
    border-radius: .5rem;
    transition: border-color 150ms ease-out;

    .title {
        @extend .kmx-typography--emphasis;

        padding: 0rem 1rem;
        font-size: 1.125rem;
        font-weight: 700;
    }

    .vehicleBody {
        @extend .kmx-typography--body-2;

        color: kmx-color('slate', 600);
    }

    .offerPrice {
        @extend .kmx-typography--body-2;

        font-weight: 700;
        color: kmx-color('slate', 900);
    }

    &.selected {
        border: 2px solid kmx-color('blue', 600);
        transition: border-color 150ms ease-out;

        .check {
            opacity: 1;
            transition: opacity 150ms ease-out;
        }
    }

    .check {
        opacity: 0;
        transition: opacity 150ms ease-out;
        position: absolute;
        background-color: kmx-color('blue', 600);
        top: -1px;
        right: -1px;
        width: 48px;
        height: 48px;
        fill: $white;
        border-top-right-radius: .5rem;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);

        svg {
            width: 1.25rem;
            height: 1.25rem;
            position: absolute;
            right: .25rem;
            top: .325rem;
        }
    }

    .selection-details {
        .kmx-typography--display-1 {
            color: kmx-color('blue', 500) !important;
        }
    }

    .icon {
        text-align: right;
        margin: 0 1.5rem 0 3rem;

        @include mq($until: medium) {
            margin-left: 0.5rem;
        }

        svg {
            height: 4rem;
            width: 4rem;
            fill: kmx-color('blue', 900);
            position: relative;
            top: 0.25rem;
        }
    }

    &:hover {
        border-color: kmx-color('blue', 600);
    }
}
