@import '~@kmx/legos-card/src/index';
@import "~@kmx/legos-theme/src/index";
@import "~@kmx/legos-theme/src/colors/index";

.storeHoursCardContainer {
    display: flex;
    flex-flow: wrap;
    border: 1px solid kmx-color('slate', 200);
    border-radius: .9rem;
}

.storeHoursCardContainer hr {
    width: 100%;
}

.storeHoursMessageContainer {
    background-color: #FFD6D9;
    flex-wrap: wrap;
    width: 100%
}
