@import '~@kmx/legos-theme/src/colors/index';
@import '/src/utils/mediaQueries/mediaQueries';

.splashPage {
    background-color: kmx-color('yellow', 600);
    width: 100%;
    .splashPositioner {
        display: flex;
        justify-content: center;
        align-items: end;
        height: 330px;
    }
    svg {
        width: 266px;
        @include tablet {
            width: 500px;
        }
    }
}
.regularPage {
    @include tablet {
        padding: 36px;
    }
    svg {
        width: 111px;
        @include tablet {
            width: 250px;
        }
    }
}
